import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'pf', 'pj', 'option', 'input' ]
  static kind_of = null

  connect() {
    this.kind_of = this.inputTarget.value
    // console.log(this.kind_of)
    this._update_tab()
  }

  update(e) {
    const selected_tab = e.target.closest('a')
    if(!!selected_tab) {
      this.kind_of = selected_tab.dataset.kindof
    }
    this._update_tab()
  }

  _update_tab() {
    const selected_tab = this.element.querySelector(`[data-kindof=${this.kind_of}]`)
    if(!!selected_tab){
      this.element.querySelectorAll('.tabs a').forEach((el) =>
        el.closest('li').className = ''
      )
      selected_tab.closest('li').className = 'is-active'
    }

    switch (this.kind_of) {
      case 'pf':
        this._hide()
        this.pfTarget.style.display = ''
        this.inputTarget.value = this.kind_of
        break
      case 'pj':
        this._hide()
        this.pjTarget.style.display = ''
        this.inputTarget.value = this.kind_of
        break
      default:
        this._hide()
        document.querySelector('.all-form-fields').style.display = 'none'
        break
    }
  }

  _hide() {
    document.querySelector('.all-form-fields').style.display = ''
    this.pfTarget.style.display = 'none'
    this.pjTarget.style.display = 'none'
  }
}
