import 'stylesheets/application'
import 'images'

require('turbolinks').start()
require('channels')
require('trix')
// require('@rails/actiontext')

import '@fortawesome/fontawesome-free/css/all.min'
import 'controllers'

window.$ = window.jQuery = require('jquery')

import 'utils/alerts'
import 'utils/modals'
import 'utils/trix'
import 'utils/moment'
import 'utils/im_viewer'
import 'utils/lpignore'
import 'utils/editable_blocks'
import 'utils/form_callbacks'
import 'utils/nav'
import 'utils/select-all'
