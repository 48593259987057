import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = [ 'selectorField', 'saveButton', 'datePicker', 'nameField' ]

  connect() {
    this._shouldShowSelector()
    this._hideButton()

    const selecteBox = this.element.querySelector('select')
    const choices = new Choices(selecteBox, {
      removeItems: false,
      removeItemButton: false,
      itemSelectText: 'Click pentru a selecta',
      loadingText: 'O cliplă...',
      duplicateItemsAllowed: false,
      callbackOnInit: () => {
        selecteBox.dataset.lpignore = true
      }
    })
  }

  showSelector(e) {
    !!e.target.value ? this._showSelector() : this._hideSelector()
  }

  showButton(e) {
    !!e.target.value ? this._showButton() : this._hideButton()
  }

  _shouldShowSelector(){
    const name = this.nameFieldTarget.querySelector('input').value
    !!name ? this._showSelector() : this._hideSelector()
  }

  _showSelector() {
    $(this.selectorFieldTarget).show()
  }
  _hideSelector() {
    $(this.selectorFieldTarget).hide()
    this._hideButton()
  }
  _showButton() {
    $(this.saveButtonTarget).show()
    $(this.datePickerTarget).show()
  }
  _hideButton() {
    $(this.saveButtonTarget).hide()
    $(this.datePickerTarget).hide()
  }
}
