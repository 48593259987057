import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'source', 'form', 'input' ]

  connect() {
    this.model        = this.data.get('model')        || 'model'
    this.name         = this.data.get('name')         || 'name'
    this.input_class  = this.data.get('input-class')  || 'input'
    this.post_url     = this.data.get('url')
  }

  toggle() {
    const toggled = this.data.get('toggled')

    if (!toggled || toggled == 0) {
      this.sourceTarget.innerHTML = this.form()
      this.data.set('toggled', 1)
      this.formTarget.querySelector('[type=text]').select()
      // $(this.formTarget).show()
    } else {
      // this.data.set('toggled', 0)
      // $(this.formTarget).hide()

    }
  }

  close(event) {
    if ((this.element.contains(event.target) === false) && this.data.get('toggled') == 1) {
      this.submit()
    }
  }

  submit() {
    const formData = new FormData(this.formTarget)

    Rails.ajax({
      url: this.post_url,
      type: 'PATCH',
      data: formData,
      success: () => {
        Turbolinks.clearCache()
        Turbolinks.visit(window.location.href)
      }
    })
  }

  form() {
    const form = `
      <form
        action="${this.post_url}"
        accept-charset="UTF-8"
        data-remote="true"
        data-action="submit->inline-edit#submit"
        data-target="inline-edit.form"
        method="post"
      >
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="_method" value="patch">
        <input type="hidden" name="authenticity_token" value="${this.authenticity_token}">
        <input
          type="text"
          value="${this.input_value}"
          name="${this.model}[${this.name}]"
          class="${this.input_class}"
          id="${this.model}_${this.name}"
          data-target="inline-edit.input"
          data-action="onblur->inline-edit#submit"
          autocomplete="off"
        >
      </form>
    `
    return form
  }

  get input_value() {
    return this.sourceTarget.textContent
  }

  get authenticity_token() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }
}
