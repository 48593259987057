const _handleEditableBlocks = event => {
  document.querySelectorAll(`[data-inline-content-edit-url]`).forEach((ele) => {
    let parentNode = ele.parentNode
    let editUrl = ele.dataset.inlineContentEditUrl
    parentNode.removeChild(ele)
    ele.removeAttribute('data-inline-content-edit-url')

    parentNode.insertAdjacentHTML('beforeend', `
      <div class="content-boxed">
        <div class="overlay">
          <a href="${editUrl}" class="button is-info is-small" data-modal="form">modifică</a>
        </div>
        <div class="content"></div>
      </div>
    `)
    parentNode.querySelector('.content').insertAdjacentElement('beforeend', ele)
  })
}

window.onload = _handleEditableBlocks
addEventListener('turbolinks:load', _handleEditableBlocks)
