import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
// import objectToFormData from 'object-to-formdata'

export default class extends Controller {
  static targets = ['products', 'orders']

  connect() {
    // if(!this.sublocationsTarget.value) {
    //   $(this.sublocationsTarget).html('<option>---</option>').prop('disabled', true)
    // }
  }

  addItem(item, quantity = 1) {
    let itemId = item.target.dataset.itemId

    fetch(this.data.get('add-item-url'), {
      method: 'post',
      body: JSON.stringify({ order: { product_id: itemId, quantity: quantity } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    }).then(res => res.json())
      .then((data) => {
        let ordersList = this.ordersTarget.querySelector('table tbody')
        let itemRow = ordersList.querySelector(`[data-order-id="${data.id}"]`)

        if(itemRow) {
          itemRow.innerHTML = data.html
        } else {
          ordersList.insertAdjacentHTML('beforeend', data.html)
        }
      })
  }

  updateOrder(e){
    let element   = e.target
    let quantity  = element.closest('tr').querySelector('input').value
    let orderUrl  = element.closest('td').querySelector('span').dataset.orderUrl

    fetch(orderUrl, {
      method: 'PATCH',
      body: JSON.stringify({ order: { quantity: quantity } }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    }).then(res => res.json())
      .then((data) => {
        console.log(data)
        element.closest('tr').innerHTML = data.html
      })
  }

  changeQuantity(e) {
    let element = e.target
    let parentElement = element.closest('td')
    let quantity = element.dataset.quantity
    let unitKind = element.dataset.unitkind
    let orderId = element.closest('tr').dataset.orderId
    let numberStep = unitKind == 'decimal' ? '0.01' : '1'
    
    element.style.display = 'none'

    if(!parentElement.querySelector('.qUpdater')){
      parentElement.insertAdjacentHTML('beforeend', `
        <div class="field has-addons has-text-centered qUpdater" style="justify-content: center;">
          <div class="control" style="width: 130px;">
            <input class="input" type="number" min="${numberStep}" step="${numberStep}" value="${quantity}" onFocus="this.select()">
          </div>
          <div class="control">
            <a class="button is-info" data-action="click->products-picker#updateOrder">OK</a>
          </div>
        </div>
      `)
    } else {
      parentElement.querySelector('.qUpdater').style.display = ''
    }

    parentElement.querySelector('input').focus()
    $(parentElement.querySelector('input')).on('keypress', (event) => {
      if(event.which === 13){
        event.target.closest('.qUpdater').querySelector('a').click()
      }
    }).on('keyup', (event) => {
      let element = event.target
      let quantity = parseInt($(element).val()) || parseInt($(element).attr('min')) || 1

      if(!$(element).val() || parseInt($(element).val()) == 0){
        $(element).val(quantity)
        $(element).select()
      }
    })

    $.each(parentElement.closest('table').querySelectorAll('.qUpdater'), (index, qu) => {
      if(orderId != qu.closest('tr').dataset.orderId){
        qu.closest('td').querySelector('span').style.display = ''
        qu.style.display = 'none'
      }
    })

  }

  load(e) {
    if(e.target.value) {
      $(e.target).parent('.select').addClass('is-loading')
      this.productsTarget.style.opacity = '0.2'
    } else {
      this.productsTarget.innerHTML = ''
    }

    if(e.target.value){
      let url = this.data.get('url')+'?repository='+e.target.value

      fetch(url)
        .then(res => res.json())
        .then(products => {
          this.productsTarget.style.opacity = '1'
          this.productsTarget.innerHTML = `
            <table class="table is-striped is-narrow is-hoverable is-fullwidth is-size-6" style="margin-top: 15px;">
              <thead>
                <tr>
                  <th width="100%">Denumire</th>
                  <th></th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          `
          $.each(products, (index, product) => {
            this.productsTarget.querySelector('table tbody').insertAdjacentHTML('beforeend', `
              <tr data-product-id="${product.id}">
                <td><small>${product.name}</small></td>
                <td>
                  <a
                    href="javascript:void(0)"
                    class="button is-small is-link"
                    data-target="products-picker.productAddButton"
                    data-action="click->products-picker#addItem"
                    data-item-id="${product.id}"
                  >Adaugă</a>
                </td>
              </tr>
            `)
          })

          $(e.target).parent('.select').removeClass('is-loading')
        })
    }
  }
}
