import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
// import Flatpickr from 'stimulus-flatpickr'

// import { Autocomplete } from 'stimulus-autocomplete'
// import { InlineEdit  } from 'stimulus-inline-edit'


const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// application.register('flatpickr', Flatpickr)
// application.register('autocomplete', Autocomplete)
// application.register('inline-edit', InlineEdit )

