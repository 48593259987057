import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect(){}
  disconnect(){}

  onAjaxSuccess(event) {
    const [data, status, xhr] = event.detail
    
    // console.log(xhr)
    Turbolinks.clearCache()
    Turbolinks.visit(window.location.href)
  }

  // onAjaxComplete(event) {
  //   console.log(1234)

  //   console.log(event)
  // }
}
