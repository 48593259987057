import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['sublocations']

  connect() {
    // if(!this.sublocationsTarget.value) {
    //   $(this.sublocationsTarget).html('<option>---</option>').prop('disabled', true)
    // }
  }

  load(e) {
    if(e.target.value) {
      $(e.target).parent('.select').addClass('is-loading')
    }

    $(this.sublocationsTarget).html('<option>---</option>').prop('disabled', true)

    if(e.target.value){
      let url = this.data.get('url')+'?location_id='+e.target.value

      fetch(url)
        .then(res => res.json())
        .then(response => {
          $.each(response, (index, el) => {
            $(this.sublocationsTarget).append("<option value='" + el.id + "'>" + el.name + "</option>")
          })

          $(this.sublocationsTarget).trigger('change').prop('disabled', false)
          $(e.target).parent('.select').removeClass('is-loading')
        })
    }
  }
}
