import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'listing' ]

  connect(){
    document.addEventListener('attachments:doc:uploaded', this._handle_upload)

    const root = this.element
    const fileInputField = root.querySelector('input[type=file]')

    fileInputField.addEventListener('change', () => {
      Array.from(fileInputField.files).forEach((file, index) => {
        const data_id = `${Date.now()}-${index}`
        this.listingTarget.insertAdjacentHTML('afterbegin', `
        <tr data-attachment-id="${data_id}">
          <td colspan="100%"><progress data-attachment-progress-id="${data_id}" class="progress is-small is-success is-radiusless" style="margin: 0px" max="100"></progress></td>
        </tr>
        `)

        this._uploadFile(file, fileInputField, data_id)
      })

      // clear the selected files from the input
      fileInputField.value = null
    })
  }

  disconnect() {
    document.removeEventListener('attachments:doc:uploaded', this._handle_upload)
  }

  _handle_upload = (event) => {
    const partial = event.detail.partial
    const attachment_signed_id = event.detail.attachment_signed_id
    $(`[data-attachment-signed-id='${attachment_signed_id}']`).replaceWith(partial)

    if(this.listingTarget.querySelectorAll('[data-attachment-id]').length > 0) {
      this.listingTarget.querySelector('.blank-row').style.display = 'none'
    } else {
      this.listingTarget.querySelector('.blank-row').style.display = ''
    }

    document.removeEventListener('attachments:doc:uploaded', this._handle_upload.bind(this))
  }


  _uploadFile(file, input, ele_id) {
    this.currid = file.name
    const url = input.dataset.directUploadUrl
    const uploader_progress = new UploaderProgress(ele_id)
    const upload = new DirectUpload(file, url, uploader_progress)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        const attachement = document.querySelector(`[data-attachment-id='${ele_id}']`)
        attachement.parentNode.removeChild(attachement)
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const attachement = document.querySelector(`[data-attachment-id='${ele_id}']`)
        attachement.dataset.attachmentSignedId = blob.signed_id

        let formData = new FormData()
        formData.append(input.name, blob.signed_id)

        this._update(formData)
      }
    })
  }

  _update(formData){
    Rails.ajax({
      url: this.data.get('update-object-url'),
      type: 'PATCH',
      data: formData,
      success: (data) => {
      },
      error: (data) => {
      }
    })
  }
}

class UploaderProgress {
  constructor(data_id) {
    this.data_id = data_id
  }
 
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('loadstart',
      event => this.directUploadLoadStart(event))

    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))

    request.upload.addEventListener('loadend',
      event => this.directUploadDidLoadEnd(event))
  }

  directUploadLoadStart(event) {
    // const attachment = document.querySelector(`[data-attachment-id='${this.data_id}'] img`)
    
    // attachment.style.opacity = '0.3'

    // console.log(event)
  }

  directUploadDidLoadEnd(event) {
    // const attachment = document.querySelector(`[data-attachment-id='${this.data_id}'] img`)
    const progressElement = document.querySelector(`[data-attachment-progress-id='${this.data_id}']`)

    // console.log(progressElement)

    // $(progressElement).fadeOut()
  }
 
  directUploadDidProgress(event) {
    const progress = Math.round(event.loaded * 100 / event.total)
    const progressElement = document.querySelector(`[data-attachment-progress-id='${this.data_id}']`)
    progressElement.value = progress

  }
}
