import Rails from '@rails/ujs'
import $ from 'jquery'
import 'jquery-confirm'
import 'jquery-confirm/css/jquery-confirm.css'

const _handleModal = event => {
  event.preventDefault()
  
  const element = event.target.closest('a')
  const location = element.href
  const save_button = element.dataset.saveButton || 'Salvează'
  const cancel_button = element.dataset.cancelButton || 'Anulează'
  const window_size = element.dataset.windowSize || 'medium'
  const default_column_class = 'column is-6 is-offset-3'

  var colum_class = default_column_class
  switch (window_size) {
    case 'medium':
      colum_class = 'column is-6 is-offset-3'
      break
    case 'big':
      colum_class = 'column is-10 is-offset-1'
      break
    case 'full':
      colum_class = 'column is-12'
      break
  }

  window.xmodal = $.confirm({
    title: true,
    // type: 'blue',
    content: 'url:' + location,
    columnClass: colum_class,
    containerFluid: true,
    escapeKey: 'cancel',
    animateFromElement: false,
    draggable: false,
    smoothContent: true,
    animation: 'zoom',
    closeAnimation: 'zoom',
    animationBounce: 1.2,
    bgOpacity: 0.5,
    animationSpeed: 200,
    backgroundDismiss: false,
    backgroundDismissAnimation: 'glow',
    onContentReady: function() {
      this.setTitle(this.$content.find('.modal-title').hide().html())

      // handle ENTER clicks
      // this.$content.find('form').keypress((e) => {
      //   if(e.which == 13)
      //     this.$$save.trigger('click') 
      // })
    },
    onOpenBefore: function() {
      document.querySelector('main').style.filter = 'blur(7px)'
      document.querySelector('main').style.transform = 'translate(0%, 3%)'    
      document.querySelector('body > section').style.filter = 'blur(7px)'

      this.$content.parents('.jconfirm-box').addClass('is-radiusless')
    },
    onClose: function(){
      document.querySelector('main').style.filter = ''
      document.querySelector('main').style.transform = ''
      document.querySelector('body > section').style.filter = ''
      document.querySelector('body > section').style.transform = ''
    },
    buttons: {
      save: {
        text: save_button,
        btnClass: 'button is-link is-radiusless',
        action: function() {
          const formElement = this.$content.find('form')[0]
          if(formElement) {
            Rails.fire(formElement, 'submit')
          } else {
            Turbolinks.visit(window.location.toString())
            // Turbolinks.visit(window.location.toString(), { action: 'replace' })
            // Turbolinks.reload
            // Turbolinks.visit(url), 1000)
            // console.log(Turbolinks)
          }
          return false
        }
      },
      cancel: {
        text: cancel_button,
        btnClass: 'button is-light is-radiusless',
        action: function() {}
      }
    }
  });
}

Rails.delegate(document, 'a[data-modal=form]', 'click', _handleModal)

// Rails.delegate(document, 'form[data-modal]', 'ajax:complete', event => {
//   const [xhr, status] = event.detail
//   console.log(xhr)
// })

// Rails.delegate(document, 'form[data-modal]', 'ajax:success', event => {
//   const [data, status, xhr] = event.detail
//   const url = xhr.getResponseHeader('Location')
//   event.data = ''

//   console.log(event)
  
//   if(url) {
//     // Redirect to url
//     // window.xmodal.close()
//     return false
//     // Turbolinks.visit(url)
//     // setTimeout(() => console.log(1234), 1000)
//     // setTimeout(() => Turbolinks.visit(url), 1000)
//   } else {
//     // window.xmodal.close()
//     return false
//   }
// })
