import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['add_item', 'template']

  connect() {}

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    
    this.add_itemTarget.insertAdjacentHTML('beforeend', content)
    this.add_itemTarget.querySelector('tr:last-child input:first-child').focus()
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(`tr`)

    item.querySelector(`input[name*='_destroy']`).value = 1
    item.style.display = 'none'
  }
}
