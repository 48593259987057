import Rails from '@rails/ujs'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['listing', 'itemRow', 'moveBtn', 'newFolderBtn', 'uploadBtn', 'deleteBtn', 'cancelBtn', 'hereBtn']
  // behaveStates = {
  //   currentRootId: null,
  //   isMoving: false,
  //   movingNotification: false,
  //   selectedItems: [],
  //   activeButtons: []
  // }

  connect(){
    Turbolinks.clearCache()
    this._updateTicks()
    this._checkStorageRoot()
    this._uiMovingState()
    // console.log('connecting:')
    // console.log(document.documentElement.hasAttribute("data-turbolinks-preview"))
  }

  disconnect(){
    // console.log('disconnecting:')
    // console.log(document.documentElement.hasAttribute("data-turbolinks-preview"))
  }

  checkItem(ele){
    let target = ele.target
    let itemId = target.dataset.id
    let activeItems = this.activeItems

    if(target.checked) {
      this.activeItems = activeItems.concat(itemId)
    } else {
      this.activeItems = activeItems.filter(e => e !== itemId)
    }
  }

  startMoving(){
    this.isMoving()
  }

  stopMoving(){
    localStorage.clear()
    this._uiMovingState()
  }

  isMoving(){
    localStorage.setItem('isMoving', true)
    localStorage.setItem('activeItems', JSON.stringify(this.activeItems))

    this._uiMovingState()
  }

  deleteItems(event){
    event.stopImmediatePropagation()
    event.preventDefault()

    // console.log(this.activeItems)
    // console.log(this.data.get('folder-url'))

    fetch(this.data.get('folder-url'), {
      method: 'DELETE',
      body: JSON.stringify({ item_ids: this.activeItems }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then(function(data) {
      Turbolinks.visit(location.toString())
      // console.log(data)
    })
  }

  movingDestination(){
    fetch(this.data.get('move-url'), {
      method: 'PATCH',
      body: JSON.stringify({ item_ids: JSON.parse(localStorage.getItem('activeItems'))}),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    })
    .then((resp) => resp.json())
    .then(function(data) {
      localStorage.clear()
      Turbolinks.visit(location.toString())
    })
  }

  _uiMovingState(){
    if(Boolean(localStorage.getItem('isMoving'))){
      this.activeItems = JSON.parse(localStorage.getItem('activeItems'))

      this.itemRowTargets.forEach(itemRow => {
        var checkbox = itemRow.querySelector('[type=checkbox]')
        checkbox.disabled = true
      })

      $('#movingMessage').show()

      $(this.cancelBtnTarget).fadeIn()
      $(this.hereBtnTarget).fadeIn()
    } else {
      localStorage.setItem('root-id', this.data.get('currentRoot'))
      this.activeItems = []
      this._updateTicks()
      $('#movingMessage').fadeOut()
  
      this.itemRowTargets.forEach(itemRow => {
        var checkbox = itemRow.querySelector('[type=checkbox]')
        checkbox.disabled = false
      })

      $(this.cancelBtnTarget).fadeOut()
      $(this.hereBtnTarget).fadeOut()
    }
  }

  _checkStorageRoot(){
    let storageRoot = localStorage.getItem('root-id')
    let domRoot = this.data.get('currentRoot')

    if(storageRoot !== domRoot){
      localStorage.clear()
      localStorage.setItem('root-id', domRoot)
    }
  }

  _showControls(){
    if(Array.isArray(this.activeItems) && this.activeItems.length){
      if(Boolean(localStorage.getItem('isMoving'))){
        $(this.moveBtnTarget).hide()
        $(this.deleteBtnTarget).hide()
      } else {
        $(this.moveBtnTarget).fadeIn()
        $(this.deleteBtnTarget).show()
      }

      $(this.newFolderBtnTarget).fadeIn()
      $(this.uploadBtnTarget).fadeIn()
    } else {
      $(this.moveBtnTarget).hide()
      $(this.deleteBtnTarget).hide()
      $(this.newFolderBtnTarget).fadeIn()
      $(this.uploadBtnTarget).fadeIn()
    }
  }

  _updateTicks(){
    this.itemRowTargets.forEach(itemRow => {
      var checkbox = itemRow.querySelector('[type=checkbox]')
      var buttons  = itemRow.querySelectorAll('.button')

      if(this.activeItems.indexOf(itemRow.dataset.itemId) > -1) {
        $(itemRow).addClass('folder-highlighted')
        itemRow.dataset.itemInactive = true
        checkbox.checked = true
        buttons.forEach(btn => { $(btn).attr('disabled', 'disabled') })
      } else {
        $(itemRow).removeClass('folder-highlighted')
        itemRow.dataset.itemInactive = false
        checkbox.checked = false
        buttons.forEach(btn => { $(btn).removeAttr('disabled') })
      }
    })

    this._showControls()
  }


  get activeItems(){
    if(!this.data.has('activeItems')) this.data.set('activeItems', '[]')
    return JSON.parse(this.data.get('activeItems'))
  }

  set activeItems(value){
    this.data.set('activeItems', JSON.stringify(value))

    this._updateTicks()
  }
}