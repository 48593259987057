addEventListener('trix-before-initialize', event => _removeTrixToolbarElements(event))
addEventListener('trix-initialize', event => _removeTrixToolbarElements(event))

const _removeTrixToolbarElements = (event) => {
  const element = event.target
  const toolbarElement = element.toolbarElement
  const groupElement = toolbarElement.querySelector(`.trix-button-group.trix-button-group--block-tools`)
  const linkElement = toolbarElement.querySelector(`.trix-button.trix-button--icon.trix-button--icon-link`)

  if(!!groupElement)
    groupElement.parentNode.removeChild(groupElement)

  if(!!linkElement)
    linkElement.parentNode.removeChild(linkElement)
}
