import Rails from '@rails/ujs'
import $ from 'jquery'
import 'jquery-confirm'
import 'jquery-confirm/css/jquery-confirm.css'

const handleConfirm = event => {
  const element = event.target
  if(element.dataset.confirm == undefined){
    return true
  }

  showConfirmationDialog(element, event)

  return false
}

const showConfirmationDialog = (link, event) => {
  const message = link.dataset.confirm

  return $.confirm({
    type: 'red',
    icon: 'fas fa-exclamation-triangle',
    escapeKey: 'cancel',
    animateFromElement: false,
    draggable: false,
    animation: 'zoom',
    closeAnimation: 'zoom',
    animationBounce: 1.2,
    animationSpeed: 200,
    title: 'Atenție!',
    content: message,
    backgroundDismiss: false,
    backgroundDismissAnimation: 'glow',
    bgOpacity: 0.5,
    columnClass: 'column is-half is-offset-one-quarter',
    containerFluid: false,
    onOpenBefore: function() {
      document.querySelector('main').style.filter = 'blur(7px)'
      document.querySelector('main').style.transform = 'translate(0%, 1%)'    
      document.querySelector('body > section').style.filter = 'blur(7px)'
      
      this.$content.parents('.jconfirm-box').addClass('is-radiusless content')
      this.$content.addClass('box')
      return this.$content
    },
    onClose: function(){
      document.querySelector('main').style.filter = ''
      document.querySelector('main').style.transform = ''
      document.querySelector('body > section').style.filter = ''
    },
    buttons: {
      confirm: {
        text: 'Da, sigur',
        btnClass: 'button is-danger is-radiusless',
        keys: ['enter'],
        action: (e) => {
          Rails.handleRemote.call(link, event)
        }
      },
      cancel: {
        text: 'Nu',
        btnClass: 'button is-light is-radiusless',
        action: () => {}
      }
    }
  })
}

Rails.delegate(document, 'a[data-confirm]', 'confirm', handleConfirm)
Rails.start()
