import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['message']

  viewMessage(event){
    let tr = event.target.closest(`tr`)
    let notification_id = tr.dataset.id

    fetch(`/notifications/${notification_id}`).then(res => res.text()).then(text => {
      tr.innerHTML = `${text}`
    })
  }
}
