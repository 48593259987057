import Rails from '@rails/ujs'

Rails.delegate(document, 'form[data-remote]', 'ajax:error', event => {
  const [response, status, xhr] = event.detail

  if(response.errors && response.model){
    const customEvent = new CustomEvent('action:error', {
      detail: response
    })
    
    document.dispatchEvent(customEvent)
  }
})
