import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = []

  connect(){
    const selectMultipleBox = this.element.querySelector('select')
    const choices = new Choices(selectMultipleBox, {
      removeItems: true,
      removeItemButton: !!selectMultipleBox.multiple,
      silent: true,
      itemSelectText: 'Click pentru a selecta',
      loadingText: 'O cliplă...',
      duplicateItemsAllowed: false,
      callbackOnInit: () => {
        selectMultipleBox.dataset.lpignore = true
      }
    })

    selectMultipleBox.addEventListener('addItem', (event) => {
      choices.hideDropdown()
    }, false)
  }
  disconnect() {}
}
