import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['output', 'breadcrumbs', 'actionButtons', 'folderSelector']
  tickedFolders = []
  isMoving = false
  movingTarget = null

  connect(){
    this._drawFolders(this.data.get('fetch-url'))
  }

  disconnect() {}

  openFolder(ele) {
    // this._drawFolders(ele.target.data.get('fetch-url'))
    // console.log(ele.target.parentNode('.i-am-folder'))
    let url = this.data.get('fetch-url')
    let folderId = $(ele.target).data('foldersId')
    this._drawFolders(`${url}/${folderId}`)
  }

  moveFolders(){
    this.isMoving = true
  }

  tickRow(ele) {
    let checkbox = $(ele.target)
    let folderId = checkbox.data('folderId')

    if(checkbox.is(':checked')){
      $(`[data-row-id=${folderId}]`).addClass('folder-highlighted')
      this.tickedFolders.push(folderId)
    } else {
      $(`[data-row-id=${folderId}]`).removeClass('folder-highlighted')
      this.tickedFolders = this.tickedFolders.filter(e => e !== folderId)
    }

    this._updateTicked()
    this._updateTickedCSS()
  }

  _updateTickedCSS(){
    this.folderSelectorTargets.forEach(ele => {
      var folderId = $(ele).data('folderId')
      if(this.tickedFolders.filter(e => e !== folderId)) {
        $(`[data-row-id=${folderId}]`).addClass('folder-highlighted')
        // $(ele).attr('checked', 'checked')
      } else {
        $(`[data-row-id=${folderId}]`).removeClass('folder-highlighted')
      }
    })
  }

  _updateTicked(){
    if(self.isMovingCurrently){
      $('tr [type=checkbox]').attr('disabled', true)
    } else {
      self.tickedFolders = []
    }

    if(Array.isArray(this.tickedFolders) && this.tickedFolders.length){
      $(this.actionButtonsTarget).find('.count')[0].innerHTML = `(${this.tickedFolders.length})`
      $(this.actionButtonsTarget).removeClass('is-invisible')
    } else {
      $(this.actionButtonsTarget).addClass('is-invisible')
    }
  }

  _drawFolders(url){
    let result = this.outputTarget
    let self = this

    fetch(url)
    .then((resp) => resp.json()) // Transform the data into json
    .then(function(data) {
      result.innerHTML = ''
      self._drawBreadcrumbs(data.self_and_ancestors, url)

      if(data.children.length > 0){
        data.children.forEach((obj, index) => {
          result.insertAdjacentHTML('beforeend', `
            <tr class="is-size-6" data-row-id="${obj['id']}">
              <td>
                <div class="is-inline-block">
                  <input class="is-checkradio" id="tick-${obj['id']}" data-folder-id="${obj['id']}" data-target="folders.folderSelector" data-action="click->folders#tickRow" type="checkbox" name="exampleCheckboxNormal">
                  <label for="tick-${obj['id']}" style="width: 0px; padding-left: 15px;"></label>
                </div>
                <div class="is-inline-block">
                  <span class="icon is-small has-text-info">
                    <span class="fa-stack">
                      <i class="fas fa-folder fa-lg"></i>
                    </span>
                  </span>
                  <a href="javascript:void(0)" data-action="click->folders#openFolder" data-folders-id="${obj['id']}">${obj['name']}</a>
                </div>
              </td>
              <td>Mapă</td>
              <td>-</td>
              <td>${obj['created_at_long']}</td>
              <td>-</td>
            </tr>
          `)
        })
      } else {
        result.insertAdjacentHTML('beforeend', `
          <tr class="is-size-7">
            <td colspan="100%">
              <center>---</center>
            </td>
          </tr>
        `)
      }

      self._updateTicked()
    })
  }

  _drawBreadcrumbs(data, url){
    if(!data)
      return false

    let self = this
    self.breadcrumbsTarget.innerHTML = '<ul></ul>'
    data.forEach((obj, index) => {
      if(data.length == index+1) {
        self.breadcrumbsTarget.querySelector('ul').insertAdjacentHTML('beforeend', `
          <li class="is-active">
            <a class="breadcrumb-item" href="javascript:void(0)">${obj.name}</a>
          </li>
        `)
      } else {
        self.breadcrumbsTarget.querySelector('ul').insertAdjacentHTML('beforeend', `
          <li>
            <a
              class="breadcrumb-item"
              href="javascript:void(0)"
              data-action="click->folders#openFolder"
              data-folders-id="${obj['id']}"
            >
              ${obj.name}
            </a>
          </li>
        `)
      }
    })
  }

  get isMovingCurrently(){
    return !!this.isMoving
  }
}
