import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    this.element.setAttribute('novalidate', true)
    document.addEventListener('action:error', this._handle_action_error)
  }

  disconnect() {
    document.removeEventListener('action:error', this._handle_action_error)
  }

  _handle_action_error = (event) => {
    const errors = event.detail.errors
    const model = event.detail.model

    this._clear_form_errors()

    for(let field in errors){
      var input = document.getElementById(`${model}_${field}`)
      if(!!input) {
        this._render_error(input, errors[field])
      }
    }

    document.removeEventListener('action:error', this._handle_action_error.bind(this))
  }

  _clear_form_errors() {
    const form = this.element
    form.querySelectorAll('input, select, textarea').forEach((element) => {
      switch(element.tagName) {
        case 'INPUT':
          this._remove_class(element, 'is-danger')
  
        case 'SELECT':
          this._remove_class(element.closest('div.select'), 'is-danger')

        default:
          this._remove_class(element, 'is-danger')
      }
    })

    form.querySelectorAll('label').forEach((label) => {
      // this._remove_class(label, 'has-text-danger')
      $(label).removeClass('has-text-white has-background-danger is-inline-block has-text-weight-light')
    })

    form.querySelectorAll('p.help.is-danger').forEach((p) => {
      p.parentNode.removeChild(p)
    })
  }

  _render_error(element, errors){
    // this._add_class(this.element.querySelector(`label[for=${element.id}]`), 'has-text-white has-background-danger')
    $(this.element.querySelector(`label[for=${element.id}]`)).addClass('has-text-white has-background-danger is-inline-block has-text-weight-light')

    switch(element.tagName) {
      case 'INPUT':
        this._add_class(element, 'is-danger')
        

      case 'SELECT':
        this._add_class(element.closest('div.select'), 'is-danger')

      default:
        this._add_class(element, 'is-danger')
    }

    errors.forEach((e) => {
      element.closest('div.control').insertAdjacentHTML('beforeend', `
        <p class="help is-danger">${e}</p>
      `)
    })
  }

  _add_class(el, className){
    if(el)
      if(el.classList)
        el.classList.add(className)
      else
        el.className += ` ${className}`
  }

  _remove_class(el, className){
    if(el)
      if (el.classList)
        el.classList.remove(className)
      else
        el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }
}
