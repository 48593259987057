import Rails from '@rails/ujs'
import 'iv-viewer/dist/iv-viewer.css'
import { FullScreenViewer } from 'iv-viewer'

const _handleViwerClick = event => {
  const viewer = new FullScreenViewer({zoomValue: 100})
  const element = event.target
  const imgSrc = element.src
  const highResolutionImage = element.dataset.highResolution

  viewer.show(imgSrc, highResolutionImage)
}

Rails.delegate(window, 'img[data-high-resolution]', 'click', _handleViwerClick)

const _handleOpenFolderClick = event => {
  event.preventDefault()
}


Rails.delegate(window, 'tr[data-item-inactive=true] a', 'click', _handleOpenFolderClick)
