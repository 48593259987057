import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect(){
    document.addEventListener('attachments:image:uploaded', this._handle_upload)

    const root = this.element
    const fileInputField = root.querySelector('input[type=file]')

    fileInputField.addEventListener('change', () => {
      Array.from(fileInputField.files).forEach((file, index) => {
        const data_id = `${Date.now()}-${index}`

        if(/\.(jpe?g|png|gif)$/i.test(file.name)){
          const reader = new FileReader()
          
          reader.addEventListener('load', () => {
            const image = new Image()
            image.style = 'padding: 4px; opacity: 0; filter: blur(1px);'
            image.title = file.name
            image.src = reader.result
            image.className = 'object-fit_cover'

            document.querySelector('#preview-image').insertAdjacentHTML('afterbegin', `
              <div class="column is-2" data-attachment-id="${data_id}">
                <div>
                  <figure class="box image is-16by9">${image.outerHTML}</figure>
                  <progress data-attachment-progress-id="${data_id}" class="progress is-small is-success is-radiusless" style="margin: 0px" min="0" max="100"></progress>
                </div>
              </div>
            `)
          }, false)

          reader.readAsDataURL(file)
        }
        
        this._uploadFile(file, fileInputField, data_id)
      })

      // clear the selected files from the input
      fileInputField.value = null
    })

  }

  disconnect() {
    document.removeEventListener('attachments:image:uploaded', this._handle_upload)
  }

  _handle_upload = (event) => {
    const partial = event.detail.partial
    const attachment_signed_id = event.detail.attachment_signed_id
    // console.log($(`[data-attachment-signed-id='${attachment_signed_id}']`))
    $(`[data-attachment-signed-id='${attachment_signed_id}']`).replaceWith(partial)

    document.removeEventListener('attachments:image:uploaded', this._handle_upload.bind(this))
  }

  _update(formData){
    Rails.ajax({
      url: this.data.get('update-object-url'),
      type: 'PATCH',
      data: formData,
      success: (data) => {
      },
      error: (data) => {
      }
    })
  }

  _uploadFile(file, input, ele_id) {
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    this.currid = file.name

    const url = input.dataset.directUploadUrl

    const uploader_progress = new UploaderProgress(ele_id)
    const upload = new DirectUpload(file, url, uploader_progress)

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        const attachement = document.querySelector(`[data-attachment-id='${ele_id}']`)
        attachement.parentNode.removeChild(attachement)

      } else {
        const attachement = document.querySelector(`[data-attachment-id='${ele_id}']`)
        attachement.dataset.attachmentSignedId = blob.signed_id

        let formData = new FormData()
        formData.append(input.name, blob.signed_id)
        this._update(formData)
      }
    })
  }
}

class UploaderProgress {
  constructor(data_id) {
    this.data_id = data_id
  }
 
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('loadstart',
      event => this.directUploadLoadStart(event))

    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))

    request.upload.addEventListener('loadend',
      event => this.directUploadDidLoadEnd(event))
  }

  directUploadLoadStart(event) {
    const attachment = document.querySelector(`[data-attachment-id='${this.data_id}'] img`)
    
    attachment.style.opacity = '0.5'
  }

  directUploadDidLoadEnd(event) {
    const attachment = document.querySelector(`[data-attachment-id='${this.data_id}'] img`)
    const progressElement = document.querySelector(`[data-attachment-progress-id='${this.data_id}']`)

    // $(progressElement).fadeOut()
  }
 
  directUploadDidProgress(event) {
    const progress = event.loaded * 100 / event.total
    const progressElement = document.querySelector(`[data-attachment-progress-id='${this.data_id}']`)

    progressElement.value = progress
  }
}
