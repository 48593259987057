import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    let notification_bell = document.getElementById('head-notification-bell')
    let parent = notification_bell.parentNode

    parent.removeChild(notification_bell)
    parent.insertAdjacentHTML('afterbegin', data.html)
  }
});
