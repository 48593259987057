import Flatpickr from 'stimulus-flatpickr'
import Rails from '@rails/ujs'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/airbnb.css'

// import the translation files and create a translation mapping
import { Romanian } from 'flatpickr/dist/l10n/ro.js'
import { english } from 'flatpickr/dist/l10n/default.js'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  locales = {
    ro: Romanian,
    en: english
  };

  connect() {
    //define locale and global flatpickr settings for all datepickers
    const locale = this.locale
    locale.rangeSeparator = ' ⸻ '
    this.config = {
      locale: locale,
      altInput: true,
      showMonths: 1,
      // enableTime: true,
      // time_24hr: true
    }

    super.connect()
  }

  // automatically submit form when a date is selected
  change(selectedDates, dateStr, instance) {
    // const form = this.element.closest('form')
    // Rails.fire(form, 'submit')
  }

  get locale() {
    if (this.locales && this.data.has('locale')) {
      return this.locales[this.data.get('locale')]
    } else {
      return ''
    }
  }
}
